var AllePressemeldungen = function(rootelement) {
	this.root = rootelement;

	this.wrapperSort = jQuery('.architektour-filter-sortiment', this.root);

	this.selectedSortKey = null;
	this.selectedSortLabel = null;

	var aa = this;
	jQuery('li', this.wrapperSort).on('click', function(event){event.preventDefault();aa.onSortClick.call(aa, this)});

	var initialSort = this.wrapperSort.find('li[data-key="all"]');
	if (initialSort != null && initialSort.length === 1) {
		this.selectSort(initialSort.data('key'), initialSort.text());
	}

	jQuery(window).bind('resize', this.updateDisplay.bind(this));
	jQuery(window).bind('orientationchange', this.updateDisplay.bind(this));
};

AllePressemeldungen.prototype.onSortClick = function(buttonElem) {
	var button = jQuery(buttonElem);

	var key = button.data('key');
	if (typeof key === "undefined") {
		return;
	}

	this.selectSort(key, jQuery(button).text());
};
AllePressemeldungen.prototype.selectSort = function(key, label) {
	this.selectedSortKey = key;
	this.selectedSortLabel = label;

	this.updateDisplay();
}
AllePressemeldungen.prototype.updateDisplay = function() {

	// Mobile Dropdown aktualisieren
	jQuery('.selectedval', this.wrapperSort).text(this.selectedSortLabel);

	// Selektion resetten
	jQuery('li', this.wrapperSort).removeClass('architektouren-typ--active');
	// Neue Selektion markieren
	jQuery('li[data-key="' + this.selectedSortKey + '"]', this.wrapperSort).addClass('architektouren-typ--active');

	// Architektouren ein/ausblenden
	var aa = this;
	jQuery('.architektour', this.root).each(function(i, e){
		var elem = jQuery(e);
		if (aa.selectedSortKey === 'all' || elem.hasClass('architektour--' + aa.selectedSortKey)) {
			elem.show();
		}
		else {
			elem.hide();
		}
	});
	jQuery('.filter', this.root).removeClass('filter-open');
};



(function(){
	jQuery('.AlleArchitektouren--Presse').each(function(i,e){
		new AllePressemeldungen(jQuery(e));
	});
})();
